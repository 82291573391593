import { http } from '@/http/axios.js'

// 列表
export function getList(params) {
  return http({
    url: `/company/teacher/list`,
    method: 'get',
    params
  })
}

export function getAllList(params) {
  return http({
    url: `/company/teacher/getAllList`,
    method: 'get',
    params
  })
}

// 状态编辑
export function changeStatus(data) {
  return http({
    url: `/company/teacher/changeStatus`,
    method: 'post',
    data
  })
}

export function removeTeacher(params) {
  return http({
    url: `/company/teacher/delete`,
    method: 'post',
    params: params
  })
}
export function addTeacher(params) {
  return http({
    url: `/company/teacher/add`,
    method: 'post',
    params: params
  })
}

export function editTeacher(params) {
  return http({
    url: `/company/teacher/edit`,
    method: 'post',
    params: params
  })
}

export function detail(id) {
  return http({
    url: `/company/teacher/detail`,
    method: 'get',
    params: { id: id }
  })
}
