import { http } from '@/http/axios.js'



// 系统 - 字典
export const DICT_SYSTEM_SEX = 'system_sex' // 性别
export const DICT_SYSTEM_STATUS = 'system_status' //状态
export const DICT_SYSTEM_EMPLOYEE_LEVEL = 'system_employee_level' //员工级别

export const DICT_SYSTEM_EMPLOYEE_STATUS = 'system_employee_status' //员工级别



export const CURRENT_STATUS = [
  {
    name: '逾期',
    value: 2
  },
  {
    name: '正常',
    value: 1
  }
]
export const BILL_STATUS = [
  {
    name: '已付款',
    value: 1
  },
  {
    name: '未付款',
    value: 0
  }
]
export const MENU_TYPE = [
  { name: '目录', value: 1 },
  { name: '菜单', value: 2 },
  { name: '按钮', value: 3 }
]

// 同步常量
export const SYNC_ZUJI = '111'
export function getDictEnumListAPI(dict_key) {
  return http({
    url: '/company/basic/dictValue/dictEnums',
    method: 'get',
    params: { dict_key }
  }).then(res => {
    return res.map(item => {
      return {
        name: item.enumText,
        value: item.enumCode
      }
    })
  })
}
export const token =
  'eyJhbGciOiJIUzUxMiJ9.eyJ1c2VyTmFtZSI6Iui2hee6p-euoeeQhuWRmCIsImV4cCI6MTY3ODY5ODg4NiwidXNlcklkIjoxLCJpYXQiOjE2Nzc4MzQ4ODZ9.XUFKn5BRIACIA-PToXxHVgEfALxd3sddtLBnFKy84AvY9vyILjHU6xykH0uJ6lajYoUs9T0D4sRbPKqIkzTZ6g'
